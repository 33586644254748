.fullArea {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.activeLabel {
  z-index: 1;
}

.labelContainer {
  &:hover {
    z-index: 1;
  }

  &:focus {
    outline: none;
  }
}

.infoCardContainer {
  z-index: 1;
}

.closeMap {
  position: absolute;
  /* right: -25px; */
  z-index: 1;
  top: 13px;
  right: 15px;
  border: none;
  cursor: pointer;
}
.closeListMap {
  position: absolute;
  z-index: 1;
  bottom: 16px;
  right: 0;
  left: 0;
  border: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;

  background-color: #E27757;
    width: fit-content;
    border-radius: 20px;
    padding: 2px 8px;
    color: #fff;
    margin: 0 auto;
    box-shadow: 0px 3px 0px 0px #dfc6b8;
    & svg{
      width: 30px;
      height: 30px;
    }
    @media (min-width: 768px) {
      display: none;
      
    }
}
